import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import teacher from './slices/teacherSlice'
import student from './slices/studentSlice'
import arch from './slices/archiveSlice'
import course from './slices/courseSlice'
import group from './slices/groupSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        teacher,
        student,
        group,
        course,
        auth,
        arch,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
