import { students } from 'utils/api_urls'
import Request from 'utils/request'

const StudentService = {}

StudentService.StudentList = function ({ pageNumber, pageSize, query }) {
	return Request.postRequest(`${students}?pageNumber=${pageNumber}&pageSize=${pageSize}&query=${query}`)
}

StudentService.StudentGetOne = function (id) {
	return Request.getRequest(`${students}?id=${id}`)
}

StudentService.StudentCreate = function (data) {
	return Request.postRequest(`${students}/signup`, data)
}

StudentService.StudentDelete = function (data) {
	return Request.deleteRequest(`${students}/archive?id=${data}`)
}

StudentService.StudentDeleteForce = function (data) {
	return Request.deleteRequest(`${students}/delete?id=${data}`)
}

StudentService.StudentArchiveList = function ({ pageNumber='', pageSize='', query='' }) {
	return Request.postRequest(`${students}/listArch?pageNumber=${pageNumber}&pageSize=${pageSize}&query=${query}`)
}
export default StudentService