import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import TeacherService from 'services/TeacherService';

export const initialState = {
	status: 'idle', // idle, | "loading", | "succeeded", | "failed"
	error: null,
	teachers: [],
    pageNumber: 1,
    totalPage: 1,
    pageSize: 10
}

export const getTeachers = createAsyncThunk("techers", async (data, { rejectWithValue }) => {
    const {pageNumber, pageSize, query} = data
    try{
        const teacher_list = await TeacherService.TeacherList({pageNumber, pageSize, query})

        return {
            teachers: teacher_list.data.teacher.map(teacher => {
                return {
                    ...teacher,
                    full_name: teacher.first_name + " " + (teacher.last_name??"") + " " + (teacher.middle_name??"")
                }
            }),
            totalPage: teacher_list.data.total_page
        }
    }catch(err){
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const teacherSlice = createSlice({
	name: 'teachers',
	initialState,
    reducers: {
        changeStatus: (state, _) => {
			state.status = "idle"
		},
        changePageNumber: (state, action) => {
            state.status = "idle";
            state.pageNumber = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTeachers.pending, (state, _)=> {
                state.status = "loading"
            })
            .addCase(getTeachers.fulfilled, (state, action) =>{
                state.status = 'succeeded'
                state.teachers = action.payload?.teachers
                state.totalPage = action.payload?.totalPage
            })
            .addCase(getTeachers.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })
    }
})

export const getTEachersList = (state) => state.teacher.teachers
export const getTEachersStatus = (state) => state.teacher.status
export const getTEachersError = (state) => state.teacher.error
export const getPageNumber = (state) => state.teacher.pageNumber
export const getPageSize = (state) => state.teacher.pageSize
export const getTotalPage = (state) => state.teacher.totalPage

export const {
	changeStatus, changePageNumber
} = teacherSlice.actions

export default teacherSlice.reducer