import fetch from 'auth/FetchInterceptor'
import { auth_signin } from 'utils/api_urls'
import Request from 'utils/request'

const AuthService = {}

AuthService.login = function (data) {
	return Request.postRequest(auth_signin, data)
}

AuthService.register = function (data) {
	return fetch({
		url: '/auth/register',
		method: 'post',
		data: data
	})
}

AuthService.logout = function () {
	return fetch({
		url: '/auth/logout',
		method: 'post'
	})
}

AuthService.loginInOAuth = function () {
	return fetch({
		url: '/auth/loginInOAuth',
		method: 'post'
	})
}

AuthService.me = function (data) {
	return Request.postRequest(`auth/me`)
}

export default AuthService;