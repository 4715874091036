import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CourseService from 'services/CourseService'

export const initialState = {
	status: 'idle', // idle, | "loading", | "succeeded", | "failed"
	error: null,
	courses: [],
    pageNumber: 1,
    pageSize: 10
}

export const getCourses = createAsyncThunk("courses", async (data, { rejectWithValue }) => {
    const {pageNumber, pageSize, query} = data
    try{
        const course_list = await CourseService.CourseList({pageNumber, pageSize, query})
        return course_list.data.course;
    }catch(err){
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const getCourse = async (data) => {
    const {pageNumber, pageSize, query} = data
    try{
        const course_list = await CourseService.CourseList({pageNumber, pageSize, query})
        return course_list.data.course;
    }catch(err){
        return Error
    }
}

export const courseSlice = createSlice({
	name: 'course',
	initialState,
    reducers: {
        changeStatus: (state, _) => {
			state.status = "idle"
		},
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCourses.pending, (state, _)=> {
                state.status = "loading"
            })
            .addCase(getCourses.fulfilled, (state, action) =>{
                state.status = 'succeeded'
                state.courses = action.payload
            })
            .addCase(getCourses.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })
    }
})

export const getCourseList = (state) => state.course.courses
export const getCourseStatus = (state) => state.course.status
export const getCourseError = (state) => state.course.error
export const getPageNumber = (state) => state.course.pageNumber
export const getPageSize = (state) => state.course.pageSize

export const {
	changeStatus
} = courseSlice.actions

export default courseSlice.reducer