import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import GroupService from 'services/GroupService'

export const initialState = {
	status: 'idle', // idle, | "loading", | "succeeded", | "failed"
	error: null,
	groups: [],
    pageNumber: 1,
    pageSize: 10
}

export const getGroups = createAsyncThunk("groups", async (data, { rejectWithValue }) => {
    const {pageNumber, pageSize} = data
    try{
        const group_list = await GroupService.GroupList({pageNumber, pageSize})
        return group_list?.data?.group;
    }catch(err){
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const groupSlice = createSlice({
	name: 'group',
	initialState,
    reducers: {
        changeStatus: (state, _) => {
			state.status = "idle"
		},
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGroups.pending, (state, _)=> {
                state.status = "loading"
            })
            .addCase(getGroups.fulfilled, (state, action) =>{
                state.status = 'succeeded'
                state.groups = action.payload
            })
            .addCase(getGroups.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })
    }
})

export const getGroupPageNumber = (state) => state.group.pageNumber
export const getGroupStatus = (state) => state.group.status
export const getGroupList = (state) => state.group.groups
export const getGroupError = (state) => state.group.error
export const getGroupPageSize = (state) => state.group.pageSize

export const {
	changeStatus
} = groupSlice.actions

export default groupSlice.reducer