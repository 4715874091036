import { groups } from 'utils/api_urls'
import Request from 'utils/request'

const GroupService = {}

GroupService.GroupList = function ({ pageNumber, pageSize, query = '' }) {
	const group = Request.postRequest(`${groups}?pageNumber=${pageNumber}&pageSize=${pageSize}&query=${query}`)
	return group
}

GroupService.GroupCreate = function (data) {
	const group = Request.postRequest(`${groups}/create`, data)
	return group
}

GroupService.GroupDelete = function (data) {
	return Request.deleteRequest(`${groups}/delete?id=${data}`)
}

GroupService.GroupGetOne = function (id) {
	return Request.getRequest(`${groups}?id=${id}`)
}

GroupService.GroupDelete = function (data) {
	return Request.deleteRequest(`${groups}/delete?id=${data}`)
}

GroupService.GroupAddStudent = function (data) {
	return Request.postRequest(`${groups}/addStudent`, data)
}

GroupService.GroupAddTeacher = function (data) {
	return Request.postRequest(`${groups}/addTeacher`, data)
}

GroupService.ChangeGroupSubsSts = function (data) {
	return Request.postRequest(`${groups}/chgnSubs`, data)
}

GroupService.ChangeGroupSts = function (data) {
	return Request.postRequest(`${groups}/chgnGrps`, data)
}

GroupService.GetByStudent = function ({ studentId = '' }) {
	return Request.getRequest(`${groups}/getByStudent?studentId=${studentId}`)
}

GroupService.GetStudents = function ({ groupId = '' }) {
	return Request.getRequest(`${groups}/getByGroup?groupId=${groupId}`)
}

export default GroupService