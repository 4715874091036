import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'apps.leads',
        path: `${APP_PREFIX_PATH}/leads`,
        component: React.lazy(() => import('views/app-views/apps/leads')),
    },
    {
        key: 'apps.teachers',
        path: `${APP_PREFIX_PATH}/teachers`,
        component: React.lazy(() => import('views/app-views/apps/teachers')),
    },
    {
        key: 'apps-teachers-addTeacher',
        path: `${APP_PREFIX_PATH}/apps/teachers/add-teacher`,
        component: React.lazy(() => import('views/app-views/apps/teachers/add-teacher')),
    },
    {
        key: 'apps.teachers.edit-teacher',
        path: `${APP_PREFIX_PATH}/apps/teachers/edit-teacher/:id`,
        component: React.lazy(() => import('views/app-views/apps/teachers/edit-teacher')),
    },
    {
        key: 'apps-teachers-teachersList',
        path: `${APP_PREFIX_PATH}/apps/teachers/teacher-list`,
        component: React.lazy(() => import('views/app-views/apps/teachers/teacher-list')),
    },
    {
        key: 'apps.teachers.info-teacher',
        path: `${APP_PREFIX_PATH}/apps/teachers/info-teacher/:id`,
        component: React.lazy(() => import('views/app-views/apps/teachers/info-teacher')),
    },
    {
        key: 'apps-teachers-addToGroup',
        path: `${APP_PREFIX_PATH}/apps/teachers/add-to-group/:id`,
        component: React.lazy(() => import('views/app-views/apps/teachers/add-to-group')),
    },
    
    {
        key: 'apps.students',
        path: `${APP_PREFIX_PATH}/students`,
        component: React.lazy(() => import('views/app-views/apps/students')),
    },
    {
        key: 'apps-students-addStudent',
        path: `${APP_PREFIX_PATH}/apps/students/add-student`,
        component: React.lazy(() => import('views/app-views/apps/students/add-student')),
    },
    {
        key: 'apps-students-addToGroup',
        path: `${APP_PREFIX_PATH}/apps/students/add-to-group/:id`,
        component: React.lazy(() => import('views/app-views/apps/students/add-to-group')),
    },
    {
        key: 'apps.students.edit-student',
        path: `${APP_PREFIX_PATH}/apps/students/edit-student/:id`,
        component: React.lazy(() => import('views/app-views/apps/students/edit-student')),
    },
    {
        key: 'apps-students-studentsList',
        path: `${APP_PREFIX_PATH}/apps/students/student-list`,
        component: React.lazy(() => import('views/app-views/apps/students/student-list')),
    },
    {
        key: 'apps.students.info-student',
        path: `${APP_PREFIX_PATH}/apps/students/info-student/:id`,
        component: React.lazy(() => import('views/app-views/apps/students/info-student')),
    },

    {
        key: 'apps-students-studentsHistory',
        path: `${APP_PREFIX_PATH}/apps/students/history`,
        component: React.lazy(() => import('views/app-views/apps/students/history')),
    },
    
    {
        key: 'apps.groups',
        path: `${APP_PREFIX_PATH}/groups`,
        component: React.lazy(() => import('views/app-views/apps/groups')),
    },
    {
        key: 'apps-groups-addGroup',
        path: `${APP_PREFIX_PATH}/apps/groups/add-group`,
        component: React.lazy(() => import('views/app-views/apps/groups/add-group')),
    },
    // {
    //     key: 'apps-groups-addGroup',
    //     path: `${APP_PREFIX_PATH}/apps/groups/group-student/:id`,
    //     component: React.lazy(() => import('views/app-views/apps/groups/add-group')),
    // },
    {
        key: 'apps.groups.edit-group',
        path: `${APP_PREFIX_PATH}/apps/groups/edit-group/:id`,
        component: React.lazy(() => import('views/app-views/apps/groups/edit-group')),
    },
    {
        key: 'apps-groups-groupsList',
        path: `${APP_PREFIX_PATH}/apps/groups/group-list`,
        component: React.lazy(() => import('views/app-views/apps/groups/group-list')),
    },
    {
        key: 'apps-groups-groupStudent',
        path: `${APP_PREFIX_PATH}/apps/groups/group-student/:id`,
        component: React.lazy(() => import('views/app-views/apps/groups/group-student')),
    },

    //
    {
        key: 'apps.course',
        path: `${APP_PREFIX_PATH}/course`,
        component: React.lazy(() => import('views/app-views/apps/course')),
    },
    {
        key: 'apps-course-addCourse',
        path: `${APP_PREFIX_PATH}/apps/course/add-course`,
        component: React.lazy(() => import('views/app-views/apps/course/add-course')),
    },
    {
        key: 'apps.course.edit-course',
        path: `${APP_PREFIX_PATH}/apps/course/edit-course/:id`,
        component: React.lazy(() => import('views/app-views/apps/course/edit-course')),
    },
    {
        key: 'apps-course-courseList',
        path: `${APP_PREFIX_PATH}/apps/course/course-list`,
        component: React.lazy(() => import('views/app-views/apps/course/course-list')),
    },
    //

    {
        key: 'pages.setting',
        path: `${APP_PREFIX_PATH}/pages/edit/*`,
        component: React.lazy(() => import('views/app-views/pages/setting/')),
    },
    {
        key: 'error-page-1',
        path: `${APP_PREFIX_PATH}/error-page-1`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
        meta: {
            blankLayout: true
        }
    },
    {
        key: 'dashboard.all-payments',
        path: `${APP_PREFIX_PATH}/dashboards/all-payments`,
        component: React.lazy(() => import('views/app-views/dashboards/all-payments')),
    },
    {
        key: 'dashboard.withdraw',
        path: `${APP_PREFIX_PATH}/dashboards/withdraw`,
        component: React.lazy(() => import('views/app-views/dashboards/withdraw')),
    },
    {
        key: 'dashboard.total-expenses',
        path: `${APP_PREFIX_PATH}/dashboards/total-expenses`,
        component: React.lazy(() => import('views/app-views/dashboards/total-expenses')),
    },
    {
        key: 'dashboard.salaries',
        path: `${APP_PREFIX_PATH}/dashboards/salaries`,
        component: React.lazy(() => import('views/app-views/dashboards/salaries')),
    },
    {
        key: 'components-calendar',
        path: `${APP_PREFIX_PATH}/apps/calendar`,
        component: React.lazy(() => import('views/app-views/apps/calendar')),
    },
]