import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import StudentService from 'services/StudentService'

export const initialState = {
    status: 'idle', // idle, | "loading", | "succeeded", | "failed"
    error: null,
    students: [],
    pageNumber: 1,
    totalPage: 1,
    pageSize: 10
}

export const getStudents = createAsyncThunk("students", async (data, { rejectWithValue }) => {
    const { pageNumber, pageSize, query } = data
    try {
        const student_list = await StudentService.StudentArchiveList({ pageNumber, pageSize, query })
        return {
            students: student_list?.data?.student.map(student => {
                return {
                    ...student,
                    full_name: student?.first_name + " " + (student?.last_name ?? "") + " " + (student?.father_name ?? ""),

                }
            }),
            totalPage: student_list?.data?.total_page
        }
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const studentSlice = createSlice({
    name: 'students',
    initialState,
    reducers: {
        changeStatusStudent: (state, _) => {
            state.status = "idle"
        },
        changePageNumber: (state, action) => {
            state.status = "idle";
            state.pageNumber = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getStudents.pending, (state, _) => {
                state.status = "loading"
            })
            .addCase(getStudents.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.students = action.payload?.students
                state.totalPage = action.payload?.totalPage
            })
            .addCase(getStudents.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })
    }
})

export const getStudentsList = (state) => state.student.students
export const getStudentsStatus = (state) => state.student.status
export const getStudentsError = (state) => state.student.error
export const getPageNumber = (state) => state.student.pageNumber
export const getPageSize = (state) => state.student.pageSize
export const getTotalPage = (state) => state.student.totalPage

export const {
    changeStatusStudent, changePageNumber
} = studentSlice.actions

export default studentSlice.reducer