import { teachers } from 'utils/api_urls'
import Request from 'utils/request'

const TeacherService = {}

TeacherService.TeacherList = function ({pageNumber, pageSize, query=''}) {
	return Request.postRequest(`${teachers}?pageNumber=${pageNumber}&pageSize=${pageSize}&query=${query}`)
}

TeacherService.TeacherCreate = function (data) {
	return Request.postRequest(`${teachers}/signup`,data)
}

TeacherService.TeacherDelete = function (data) {
	return Request.deleteRequest(`${teachers}/delete?id=${data}`)
}

TeacherService.TeacherGetOne = function (id) {
	return Request.getRequest(`${teachers}?id=${id}`)
}

export default TeacherService