import { courses } from 'utils/api_urls'
import Request from 'utils/request'

const CourseService = {}

CourseService.CourseList = function ({pageNumber, pageSize, query=''}) {
	return Request.postRequest(`${courses}?pageNumber=${pageNumber}&pageSize=${pageSize}&query=${query}`)
}

CourseService.CourseCreate = function (data) {
	return Request.postRequest(`${courses}/create`,data)
}

CourseService.CourseDelete = function (data) {
	return Request.deleteRequest(`${courses}/delete?id=${data}`)
}

CourseService.CourseGetOne = function (id) {
	return Request.getRequest(`${courses}?id=${id}`)
}

export default CourseService